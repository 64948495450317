import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

import SEO from '../components/seo';

import { Item, Menu } from '../styles/styles.js';

const Cervejas = ({ data }) => {
  return (
    <>
      <SEO
        title="Cervejas"
        keywords={[
          `Coca Cola`,
          `Fanta laranja`,
          `Soda`,
          `Dolly`,
          `Skol`,
          `Itaipava`,
          `Amstel`,
        ]}
      />
      <Menu className="menu">
        <Slide left cascade>
          <h1 className="menu-title">Cervejas</h1>
        </Slide>

        <Fade left>
          <div className="item-image-bebidas">
            <Img
              fluid={data.cervejas.childImageSharp.fluid}
              alt="Cervejas vários sabores"
            />
          </div>
        </Fade>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Amstel Lata 269ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Amstel Garrafa 600ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">12,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Antarctica Sub Zero 269ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>
        </Item>

        {/* <Item>
          <div className="item-separator">
            <h4 className="item-title">Antarctica Original 600ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">12,00</h4>
            </div>
          </div>
        </Item> */}

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Barbacan Pilsen 1L</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">22,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Brahma Lata 269ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Brahma Chopp Long Neck 355ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Brahma Malzbier Long Neck 355ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Brahma Zero Long Neck 355ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Brahma Duplo Malte Lata 269ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Brahma Duplo Malte 600ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">11,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Brahma Garrafa 600ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">11,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Budweiser Lata 269ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">7,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Budweiser Long Neck 330ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">9,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Budweiser Zero Long Neck 330ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">9,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Burguesa Lata 269ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">4,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Cabaré Puro Malte 330ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">11,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Caracu Long Neck 355ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">10,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Corona Long Neck 330ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">10,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Coronita 210ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Eisenbahn Pilsen LN 355ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">10,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Eisenbahn Pilsen Garrafa 600ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">12,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">
              Estrella Galicia Lager Puro Malte 355ml
            </h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">10,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Heineken Lata 269ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Heineken Long Neck 330ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">12,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Heineken Zero Long Neck 330ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">12,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Heineken 600ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">20,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Império Lager Long Neck 275ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">9,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Império Gold Long Neck 210ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Itaipava Pilsen Lata 269ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">4,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Itaipava Pilsen LN 300ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">3,50</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Itaipava Go Draft Long Neck 330ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,50</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Itaipava Malzbier Long Neck 330ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">7,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Itaipava Malzbier Long Neck 355ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">7,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Itaipava Zero Long Neck 355ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Original Lata 269ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Original Garrafa 600ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">13,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Skol Lata 269ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Skol Garrafa 600ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">11,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Spaten 355ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Spaten 600ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">13,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Stella Artois 330ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,90</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Stella Artois 600ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">14,00</h4>
            </div>
          </div>
        </Item>
      </Menu>
    </>
  );
};

export const query = graphql`
  query {
    cervejas: file(relativePath: { eq: "cervejas-brush.png" }) {
      childImageSharp {
        fluid(maxWidth: 275, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Cervejas;
